<template>
  <div>
    <div v-if="!editMode">
      <g-title>翻译等级</g-title>
      <a-row :gutter="16" class="mb-2">
        <a-col :span="4"> <div class="py-3">等级</div> </a-col>
        <a-col :span="5"> <div class="py-3">月平均分</div> </a-col>
        <a-col :span="5"> <div class="py-3">月任务量(话)</div> </a-col>
        <a-col :span="5"> <div class="py-3">合作时长(月)</div> </a-col>
        <a-col :span="5"> <div class="py-3">单价</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="4">
          <label>高级</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.highLevel.averageScore }}</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.highLevel.taskCountMonthly }}</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.highLevel.cooperationTime }}</label>
        </a-col>
        <a-col :span="5">
          <label>¥ {{ formData.data.highLevel.unitPrice }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="4">
          <label>中级</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.mediumLevel.averageScore }}</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.mediumLevel.taskCountMonthly }}</label>
        </a-col>
        <a-col :span="5">
          <label>> {{ formData.data.mediumLevel.cooperationTime }}</label>
        </a-col>
        <a-col :span="5">
          <label>¥ {{ formData.data.mediumLevel.unitPrice }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="4">
          <label>低级</label>
        </a-col>
        <a-col :span="5">
          <label>≤ {{ formData.data.lowLevel.averageScore }}</label>
        </a-col>
        <a-col :span="5">
          <label>≤ {{ formData.data.lowLevel.taskCountMonthly }}</label>
        </a-col>
        <a-col :span="5">
          <label>≤ {{ formData.data.lowLevel.cooperationTime }}</label>
        </a-col>
        <a-col :span="5">
          <label>¥ {{ formData.data.lowLevel.unitPrice }}</label>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <g-title>翻译等级</g-title>
      <a-row :gutter="16">
        <a-col :span="4"> <div class="py-3">等级</div> </a-col>
        <a-col :span="5"> <div class="py-3">月平均分</div> </a-col>
        <a-col :span="5"> <div class="py-3">月任务量(话)</div> </a-col>
        <a-col :span="5"> <div class="py-3">合作时长(月)</div> </a-col>
        <a-col :span="5"> <div class="py-3">单价</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="4">
          <label>高级</label>
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.highLevel.averageScore" placeholder="请输入平均分" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.highLevel.taskCountMonthly" placeholder="请输入任务量" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.highLevel.cooperationTime" placeholder="请输入合作时长" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="¥" v-model="formData.data.highLevel.unitPrice" placeholder="请输入单价" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="4">
          <label>中级</label>
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.mediumLevel.averageScore" placeholder="请输入平均分" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.mediumLevel.taskCountMonthly" placeholder="请输入任务量" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="＞" v-model="formData.data.mediumLevel.cooperationTime" placeholder="请输入合作时长" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="¥" v-model="formData.data.mediumLevel.unitPrice" placeholder="请输入单价" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="4">
          <label>低级</label>
        </a-col>
        <a-col :span="5">
          <a-input prefix="≤" v-model="formData.data.lowLevel.averageScore" placeholder="请输入平均分" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="≤" v-model="formData.data.lowLevel.taskCountMonthly" placeholder="请输入任务量" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="≤" v-model="formData.data.lowLevel.cooperationTime" placeholder="请输入合作时长" />
        </a-col>
        <a-col :span="5">
          <a-input prefix="¥" v-model="formData.data.lowLevel.unitPrice" placeholder="请输入单价" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    propsFormData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      labelWidth: '140',
      layout: {
        labelCol: {
          style: 'width: 140px',
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      formData: this.propsFormData,
    }
  },
}
</script>

<style scoped>
label {
  line-height: 30px;
}

.py-3 {
  font-weight: bold;
  color: #333;
}
</style>