var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.editMode
      ? _c(
          "div",
          [
            _c("g-title", [_vm._v("翻译等级")]),
            _c(
              "a-row",
              { staticClass: "mb-2", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("月平均分")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("月任务量(话)")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("合作时长(月)")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("单价")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("高级")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " + _vm._s(_vm.formData.data.highLevel.averageScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(_vm.formData.data.highLevel.taskCountMonthly)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " + _vm._s(_vm.formData.data.highLevel.cooperationTime)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "¥ " + _vm._s(_vm.formData.data.highLevel.unitPrice)
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("中级")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " + _vm._s(_vm.formData.data.mediumLevel.averageScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(_vm.formData.data.mediumLevel.taskCountMonthly)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(_vm.formData.data.mediumLevel.cooperationTime)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "¥ " + _vm._s(_vm.formData.data.mediumLevel.unitPrice)
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("低级")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "≤ " + _vm._s(_vm.formData.data.lowLevel.averageScore)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "≤ " + _vm._s(_vm.formData.data.lowLevel.taskCountMonthly)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v(
                      "≤ " + _vm._s(_vm.formData.data.lowLevel.cooperationTime)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("label", [
                    _vm._v("¥ " + _vm._s(_vm.formData.data.lowLevel.unitPrice)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("g-title", [_vm._v("翻译等级")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("月平均分")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("月任务量(话)")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("合作时长(月)")]),
                ]),
                _c("a-col", { attrs: { span: 5 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("单价")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("高级")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入平均分" },
                      model: {
                        value: _vm.formData.data.highLevel.averageScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highLevel,
                            "averageScore",
                            $$v
                          )
                        },
                        expression: "formData.data.highLevel.averageScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入任务量" },
                      model: {
                        value: _vm.formData.data.highLevel.taskCountMonthly,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highLevel,
                            "taskCountMonthly",
                            $$v
                          )
                        },
                        expression: "formData.data.highLevel.taskCountMonthly",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入合作时长" },
                      model: {
                        value: _vm.formData.data.highLevel.cooperationTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highLevel,
                            "cooperationTime",
                            $$v
                          )
                        },
                        expression: "formData.data.highLevel.cooperationTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "¥", placeholder: "请输入单价" },
                      model: {
                        value: _vm.formData.data.highLevel.unitPrice,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highLevel,
                            "unitPrice",
                            $$v
                          )
                        },
                        expression: "formData.data.highLevel.unitPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("中级")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入平均分" },
                      model: {
                        value: _vm.formData.data.mediumLevel.averageScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumLevel,
                            "averageScore",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumLevel.averageScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入任务量" },
                      model: {
                        value: _vm.formData.data.mediumLevel.taskCountMonthly,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumLevel,
                            "taskCountMonthly",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.mediumLevel.taskCountMonthly",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "＞", placeholder: "请输入合作时长" },
                      model: {
                        value: _vm.formData.data.mediumLevel.cooperationTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumLevel,
                            "cooperationTime",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumLevel.cooperationTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "¥", placeholder: "请输入单价" },
                      model: {
                        value: _vm.formData.data.mediumLevel.unitPrice,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumLevel,
                            "unitPrice",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumLevel.unitPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 4 } }, [
                  _c("label", [_vm._v("低级")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "≤", placeholder: "请输入平均分" },
                      model: {
                        value: _vm.formData.data.lowLevel.averageScore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.lowLevel,
                            "averageScore",
                            $$v
                          )
                        },
                        expression: "formData.data.lowLevel.averageScore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "≤", placeholder: "请输入任务量" },
                      model: {
                        value: _vm.formData.data.lowLevel.taskCountMonthly,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.lowLevel,
                            "taskCountMonthly",
                            $$v
                          )
                        },
                        expression: "formData.data.lowLevel.taskCountMonthly",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "≤", placeholder: "请输入合作时长" },
                      model: {
                        value: _vm.formData.data.lowLevel.cooperationTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.lowLevel,
                            "cooperationTime",
                            $$v
                          )
                        },
                        expression: "formData.data.lowLevel.cooperationTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: "¥", placeholder: "请输入单价" },
                      model: {
                        value: _vm.formData.data.lowLevel.unitPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.lowLevel, "unitPrice", $$v)
                        },
                        expression: "formData.data.lowLevel.unitPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }